<script lang="ts">
export default defineComponent({
    name: 'SBHomepage',
    props: {
        blok: {
            type: Object as PropType<HomepageBlokInterface>,
            required: true,
        },
        globalArticleCopy: {
            type: Object as PropType<GlobalArticleCopyInterface>,
            required: true,
        },
    },
});
</script>

<template>
    <div v-editable="blok">
        <storyblok-component
            v-for="childBlok in blok.body"
            :key="childBlok._uid"
            :blok="childBlok"
            :global-article-copy="globalArticleCopy" />
    </div>
</template>
